import Nav from '../../components/nav/nav';
// import { Link } from 'react-router-dom';
import './style.css';

const Header = (props) => (
  <header className="App-header">
    <Nav />
  </header>
)

export default Header;
