import Page from '../../components/page/page';
import Section from '../../components/section/section';
import { Download } from 'react-bootstrap-icons';

const Dokumentumok = (props) => (
  <Page title="Nyomtatványok">
    {/* Étkeztetés */}
    <Section title="Étkeztetés">
      <ol>
      <p>
          <li><a href="https://drive.google.com/file/d/1Z6MfTi8Sf06uQs_5kKf4Uis3lGCLhIeV/view?usp=sharing" download={true}>Ismertető <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1YeJQShckE_DA-202N8aJbu0f51KwkZd9/view?usp=sharing" download={true}>Tájékoztató a nyomtatványcsomag kitöltéséhez <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1eT1YJ4xPWQDbDQ0rW3g4XZfsiu1y-sTx/view?usp=sharing" download={true}>Térítési díj-táblázat <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1eKeQa3EiZi_qUA55BSttq4RZV_ejixZs/view?usp=sharing" download={true}>Jövedelemnyilatkozat <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1iTplkz1g3DOEccslWZWQbhPMUxiCGJiJ/view?usp=sharing" download={true}>Kérelem <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1SupUivyxpEx2RG7WRxhC48X2d0SfPf1i/view?usp=sharing" download={true}>Nyilatkozat adatkezeléshez <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1yihx_wJNjtFUcNNnnoEjRltbkojXz-1E/view?usp=sharing" download={true}>Nyilatkozat cselekvőképességről <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1uqXCzvI45yqFJ_SUFJWR8xzt0f3pb29g/view?usp=sharing" download={true}>Egészségi állapotra vonatkozó igazolás <Download /></a></li>
        </p>
      </ol>
    </Section>

    {/* Gondozás */}
    <Section title="Házi segítségnyújtás">
      <ol>
      <p>
          <li><a href="https://drive.google.com/file/d/1Z6MfTi8Sf06uQs_5kKf4Uis3lGCLhIeV/view?usp=sharing" download={true}>Ismertető <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1cGgQIAj5ZbLhLX6JIv-eygxFa-u75H_B/view?usp=sharing" download={true}>Tájékoztató a nyomtatványcsomag kitöltéséhez <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1N1O11NkfOBSwZWqxQ4RPHV0pucu2SC7H/view?usp=sharing" download={true}>Egészségi állapotra vonatkozó igazolás <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1OYKw5J9KnEbHf7GYzUnH0Ixvn8LMGl0c/view?usp=sharing" download={true}>Nyilatkozat cselekvőképességről <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1WhPmmTBFAxkVpBrIsatxUVn30q_iRoil/view?usp=sharing" download={true}>Kérelem <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1ynNN3e9v6GOV68r83tjxeH7O0zvMq_3g/view?usp=sharing" download={true}>Jövedelemnyilatkozat <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1qV-dEi_88Nqz93JmxWFDf9zvhBUSTh-1/view?usp=sharing" download={true}>Nyilatkozat adatkezeléshez <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1iduXIrjF9adugQ3Gk3OW3PaQjwL98yEA/view?usp=sharing" download={true}>Térítési díj-táblázat <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1Qoaww5jCMlwkW3m5RD4tEG9OeMhU0oE6/view?usp=sharing" download={true}>Értékelő-adatlap <Download /></a></li>
        </p>
      </ol>
    </Section>

    {/* Klub */}
    <Section title="Nappali ellátás">
      <ol>
      <p>
          <li><a href="https://drive.google.com/file/d/1Z6MfTi8Sf06uQs_5kKf4Uis3lGCLhIeV/view?usp=sharing" download={true}>Ismertető <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1wCTfF2ptWfCGboVWw4xg1Xp0ovDie3mb/view?usp=sharing" download={true}>Tájékoztató a nyomtatványcsomag kitöltéséhez <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1pV5UdwiM46yRwhOFOaYeCVy26P4PdOQJ/view?usp=sharing" download={true}>Kérelem <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1sacDQZ9hxwRwCwfhIzqOlSSWQMePOuOl/view?usp=sharing" download={true}>Nyilatkozat cselekvőképességről <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1obLSOtFvOsLHN7rS_WZfzrjiLUN3hRqu/view?usp=sharing" download={true}>Nyilatkozat adatkezeléshez <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/110AQG5EAQfZFZMtjAKQry472n19PJu5w/view?usp=sharing" download={true}>Adatkezelési nyilatkozat fotózáshoz <Download /></a></li>
        </p>
        <p>
          <li><a href="https://drive.google.com/file/d/1Q2KqENXAcOrOWEWroM6e1nmCmH1EooyG/view?usp=sharing" download={true}>Egészségi állapotra vonatkozó igazolás <Download /></a></li>
        </p>
      </ol>
    </Section>

  </Page >
);

export default Dokumentumok;
